 
import Tooltip from 'bootstrap/js/dist/tooltip';
import Modal from 'bootstrap/js/dist/modal';
import Dropdown from 'bootstrap/js/dist/dropdown';
import Tab from 'bootstrap/js/dist/tab';
import Alert from 'bootstrap/js/dist/alert';

window.Modal = Modal;

const tooltipTriggerList = [].slice.call(document.querySelectorAll('[role*="tooltip"]'));
tooltipTriggerList.map(function (el) {
  return new Tooltip(el, {
    popperConfig: function (config) {
      return {};
    }
  });
});
